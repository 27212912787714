import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import ConstantAPI from '@/config/ConstantAPI';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import { FORM_MODE, OBJECT_TYPE } from '@/util/common-constant';
import { TAB_KEY } from '@/util/common-constant';

const state = () => ({
  treeGroup: [],
  groups: [],
  objectInfoActiveTab: TAB_KEY.INFO,
  formMode: FORM_MODE.CREATE,
  listTree: [],
  tabGroups: [],
  listIdentHistory: [],
  totalIdenHistory: 0,
  activeTab: '',
  objectName: '',
  currentTree: {},
  formState: {
    violation: [],
    objectType: undefined,
    name: '',
    characteristic: '',
    imagesAvatar: [],
    imagesRelate: [],
    imagesRelateAdded: [],
    imagesRelateDeleted: [],
    ioGroups: [],
    relatedSuggestions: null,
  },
  formHuman: {
    address: '',
    country: null,
    dateOfBirth: null,
    dateOfIssue: null,
    districtId: undefined,
    email: '',
    id: '',
    exhibitDetail: '',
    fatherExhibit: '',
    fatherName: '',
    gender: undefined,
    identification: '',
    imagesAvatar: [],
    imagesRelate: [],
    imagesRelateAdded: [],
    imagesRelateDeleted: [],
    insuranceCode: '',
    ioGroups: [],
    isDeleted: undefined,
    job: '',
    motherExhibit: '',
    motherName: '',
    spouseExhibit: '',
    spouseName: '',
    name: '',
    characteristic: '',
    nationality: undefined,
    note: '',
    phone: '',
    placeOfIssue: '',
    provinceId: undefined,
    realName: '',
    socialNetwork: '',
    taxCode: '',
    typeExhibit: undefined,
    wardId: undefined,
    violation: [],
  },
  formVehicle: {
    brandId: '',
    characteristic: '',
    chassisNumber: '',
    engineNumber: '',
    id: null,
    imagesAvatar: [],
    imagesRelate: [],
    imagesRelateAdded: [],
    imagesRelateDeleted: [],
    ioGroups: [],
    isDeleted: false,
    licensePlate: '',
    licensePlateType: '',
    manufacturingDate: null,
    name: '',
    note: '',
    ownerAddress: '',
    ownerIdCard: '',
    ownerVehicle: '',
    registrationAddress: '',
    registrationDate: null,
    registrationNo: '',
    vehicleColor: '',
    vehicleType: '',
  },
  formLocation: {
    address: null,
    characteristic: null,
    contactAddress: null,
    contactName: null,
    contactPhone: null,
    districtId: null,
    id: null,
    imagesAvatar: [],
    imagesRelate: [],
    imagesRelateAdded: [],
    imagesRelateDeleted: [],
    ioGroups: [],
    isDeleted: true,
    name: null,
    note: null,
    ownerAddress: null,
    ownerIdCard: null,
    ownerName: null,
    provinceId: null,
    type: null,
    wardId: null,
  },
  formSymbol: {
    name: '',
    characteristic: '',
    color: '',
    imagesAvatar: [],
    imagesRelate: [],
    imagesRelateAdded: [],
    imagesRelateDeleted: [],
    ioGroups: [],
    isDeleted: false,
    note: '',
    ownerAddress: '',
    ownerName: '',
    ownerIdCard: '',
  },
  detailObjRelate: null,
  suggestPerson: null,
  suggestVehicle: null,
  loading: false,
  listMergeProfile: [],
  listSimilarCreate: []
});
const addKey = (data) => {
  for (let item of data) {
    item.key = item.id;
    if (item.children && item.children.length > 0) {
      addKey(item.children);
    }
  }
};

const actions = {
  checkViolation({ state }) {
    const { t } = i18n.global;
    let success = true;
    for (let el of state.formState.violation) {
      if (
        el.charge === '' ||
        el.decisionNoDate === '' ||
        el.decisionUnit === ''
      ) {
        success = false;
        notification.warning({
          message: t('common.notification'),
          description: t('object_information.violation_invalid'),
          duration: 4,
        });
        break;
      }
    }
    return success;
  },
  async createHuman({ state, commit }) {
    const { t } = i18n.global;
    commit('setFormHuman');
    let idHuman = null;
    const response = await DataService.callApi(
      ConstantAPI.OBJECT_INFORMATION.human.CREATE,
      state.formHuman
    );
    checkResponse(
      response,
      (data) => {
        idHuman = data.id
        notification.success({
          message: t('common.notification'),
          description: t(
            'common.create_success',
            t('object_information.human')
          ),
          duration: 4,
        });
      },
      () => {
        notification.error({
          message: t('common.notification'),
          description: t('common.create_fail', t('object_information.human')),
          duration: 4,
        });
      }
    );
    return idHuman;
  },
  async updateHuman({ state, commit }) {
    const { t } = i18n.global;
    commit('setFormHuman');
    let isSuccess;
    const response = await DataService.callApi(
      ConstantAPI.OBJECT_INFORMATION.human.UPDATE,
      state.formHuman
    );
    checkResponse(
      response,
      () => {
        isSuccess = true;
        notification.success({
          message: t('common.notification'),
          description: t(
            'common.update_success',
            t('object_information.human')
          ),
          duration: 4,
        });
      },
      () => {
        isSuccess = false;
        notification.error({
          message: t('common.notification'),
          description: t('common.update_fail', t('object_information.human')),
          duration: 4,
        });
      }
    );
    return isSuccess;
  },
  async createVehicle({ state, commit }) {
    const { t } = i18n.global;
    commit('setFormVehicle');
    let idVehicle;
    const response = await DataService.callApi(
      ConstantAPI.OBJECT_INFORMATION.vehicle.CREATE,
      state.formVehicle
    );
    checkResponse(
      response,
      (data) => {
        idVehicle = data.id;
        notification.success({
          message: t('common.notification'),
          description: t(
            'common.create_success',
            t('object_information.vehicle')
          ),
          duration: 4,
        });
      },
      () => {
        notification.error({
          message: t('common.notification'),
          description: t('common.create_fail', t('object_information.vehicle')),
          duration: 4,
        });
      }
    );
    return idVehicle;
  },
  async updateVehicle({ state, commit }) {
    const { t } = i18n.global;
    commit('setFormVehicle');
    let isSuccess;
    const response = await DataService.callApi(
      ConstantAPI.OBJECT_INFORMATION.vehicle.UPDATE,
      state.formVehicle
    );
    checkResponse(
      response,
      () => {
        isSuccess = true;
        notification.success({
          message: t('common.notification'),
          description: t(
            'common.update_success',
            t('object_information.vehicle')
          ),
          duration: 4,
        });
      },
      () => {
        isSuccess = false;
        notification.error({
          message: t('common.notification'),
          description: t('common.update_fail', t('object_information.vehicle')),
          duration: 4,
        });
      }
    );
    return isSuccess;
  },
  async createSymbol({ state, commit }) {
    const { t } = i18n.global;
    commit('setFormSymbol');
    let isSuccess;
    const response = await DataService.callApi(
      ConstantAPI.OBJECT_INFORMATION.symbol.CREATE_SYMBOL,
      state.formSymbol
    );
    checkResponse(
      response,
      () => {
        isSuccess = true;
        notification.success({
          message: t('common.notification'),
          description: t(
            'common.create_success',
            t('object_information.symbol')
          ),
          duration: 4,
        });
      },
      () => {
        isSuccess = false;
        notification.error({
          message: t('common.notification'),
          description: t('common.create_fail', t('object_information.symbol')),
          duration: 4,
        });
      }
    );
    return isSuccess;
  },
  async updateSymbol({ state, commit }) {
    const { t } = i18n.global;
    commit('setFormSymbol');
    let isSuccess;
    const response = await DataService.callApi(
      ConstantAPI.OBJECT_INFORMATION.symbol.UPDATE_SYMBOL,
      state.formSymbol
    );
    checkResponse(
      response,
      () => {
        isSuccess = true;
        notification.success({
          message: t('common.notification'),
          description: t(
            'common.update_success',
            t('object_information.symbol')
          ),
          duration: 4,
        });
      },
      () => {
        isSuccess = false;
        notification.error({
          message: t('common.notification'),
          description: t('common.update_fail', t('object_information.symbol')),
          duration: 4,
        });
      }
    );
    return isSuccess;
  },
  async createLocation({ state, commit }) {
    const { t } = i18n.global;
    let isSuccess;
    commit('setFormLocation');
    const response = await DataService.callApi(
      ConstantAPI.OBJECT_INFORMATION.address.CREATE,
      {
        ...state.formState,
        ...state.formLocation,
      }
    );
    checkResponse(
      response,
      () => {
        isSuccess = true;
        notification.success({
          message: t('common.notification'),
          description: t(
            'common.create_success',
            t('object_information.address')
          ),
          duration: 4,
        });
      },
      () => {
        isSuccess = false;
        notification.error({
          message: t('common.notification'),
          description: t('common.create_fail', t('object_information.address')),
          duration: 4,
        });
      }
    );
    return isSuccess;
  },
  async updateLocation({ state, commit }) {
    const { t } = i18n.global;
    commit('setFormLocation');
    let isSuccess;
    const response = await DataService.callApi(
      ConstantAPI.OBJECT_INFORMATION.address.UPDATE,
      state.formLocation
    );
    checkResponse(
      response,
      () => {
        isSuccess = true;
        notification.success({
          message: t('common.notification'),
          description: t(
            'common.update_success',
            t('object_information.location')
          ),
          duration: 4,
        });
      },
      () => {
        isSuccess = false;
        notification.error({
          message: t('common.notification'),
          description: t(
            'common.update_fail',
            t('object_information.location')
          ),
          duration: 4,
        });
      }
    );
    return isSuccess;
  },
  async getDetail({ commit, state }, { id, type }) {
    commit('setLoading', true);
    let dataResponse, api, form;
    switch (type) {
      case OBJECT_TYPE.HUMAN.toString():
        api = ConstantAPI.OBJECT_INFORMATION.human.DETAIL;
        form = state.formHuman;
        break;
      case OBJECT_TYPE.VEHICLE.toString():
        api = ConstantAPI.OBJECT_INFORMATION.vehicle.DETAIL;
        form = state.formVehicle;
        break;
      case OBJECT_TYPE.LOCATION.toString():
        api = ConstantAPI.OBJECT_INFORMATION.address.DETAIL;
        form = state.formLocation;
        break;
      case OBJECT_TYPE.SYMBOL.toString():
        api = ConstantAPI.OBJECT_INFORMATION.symbol.DETAIL_SYMBOL;
        form = state.formSymbol;
        break;
    }
    const response = await DataService.callApi({
      url: api.url + id,
      method: api.method,
    });
    checkResponse(
      response,
      (data) => {
        dataResponse = data;
        Object.assign(form, data);
        Object.assign(state.formState, { ...data, imagesAvatar: [ ...data.imagesAvatar, { url: data.firstImageUrl} ] });
        state.formState.objectType = Number(type);
        state.formState.ioGroups.forEach((item) => {
          item.id = item.groupId;
          if (item.groupDetail) {
            item.name = item.groupDetail.name;
            item.warningLevel = item.groupDetail.warningLevelId;
            item.parentId = item.groupDetail.parentId;
          }
        });
        const mainImgIndex = state.formState.imagesAvatar.findIndex(
          (item) => item.isMain
        );
        commit('setObjectName', state.formState.name)
        let mainImgItem;
        if (mainImgIndex > -1) {
          mainImgItem = state.formState.imagesAvatar[mainImgIndex];
          state.formState.imagesAvatar.splice(mainImgIndex, 1);
          state.formState.imagesAvatar.unshift(mainImgItem);
        }
      },
      () => {
        dataResponse = null;
      }
    );
    commit('setLoading', false);
    return dataResponse;
  },
  resetForm({ commit }) {
    commit('resetForm');
  },
  async getTypeAddress({ commit, state }) {
    commit('setLoading', true);
    const response = await DataService.callApi(
      ConstantAPI.indentity_management.GET_APP_PARAMS,
      null,
      { type: 'AddressType' }
    );
    checkResponse(response, () => {
      state.typeAddress = response.data;
    });
    commit('setLoading', false);
  },
  async getTreeGroup({ commit }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.io_group.GET_TREE,
        null,
        {
          type: '1',
        }
      );
      addKey(response.data);
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getTreeById({ state, commit }, { id, name }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.io_group.GET_TREE_BY_ID,
        null,
        {
          id: id,
        }
      );
      checkResponse(
        response,
        (data) => {
          state.tabGroups.push({ key: id, tabName: name });
          state.listTree.push({ ...data, mainNodeId: id, mainNodeName: name });
          state.activeTab = id;
          state.currentTree =
            state.listTree[
              state.listTree.findIndex((item) => item.mainNodeId === id)
            ];
        },
        () => {}
      );
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  removeTree({ state }, id) {
    state.tabGroups = state.tabGroups.filter((item) => item.key !== id);
    state.listTree = state.listTree.filter((item) => item.mainNodeId !== id);
    state.activeTab =
      state.listTree.length === 0 ? '' : state.listTree[0].mainNodeId;
    state.currentTree =
      state.listTree.length === 0
        ? {}
        : state.listTree[
            state.listTree.findIndex(
              (item) => item.mainNodeId === state.activeTab
            )
          ];
  },
  changeTabTree({ state }, key) {
    state.activeTab = key;
    state.currentTree =
      state.listTree[
        state.listTree.findIndex((item) => item.mainNodeId === key)
      ];
  },
  setFormMode({ state }, formMode) {
    state.formMode = formMode;
  },
  setToFirstTabGroup({ state }) {
    state.activeTab =
      state.listTree.length === 0 ? '' : state.listTree[0].mainNodeId;
    state.currentTree =
      state.listTree.length === 0
        ? {}
        : state.listTree[
            state.listTree.findIndex(
              (item) => item.mainNodeId === state.activeTab
            )
          ];
  },
  setObjectInfoActiveTab({ state }, tabKey) {
    state.objectInfoActiveTab = tabKey;
  },
  async getDetailObjRelate({ state }, payload = { id: null, params: null }) {
    try {
      const response = await DataService.callApi(
        {
          url: ConstantAPI.OBJECT_MANAGEMENT.DETAIL_OBJ_RELATE.url + payload.id,
          method: ConstantAPI.OBJECT_MANAGEMENT.DETAIL_OBJ_RELATE.method,
        },
        null,
        payload.params
      );
      checkResponse(response, () => {
        state.detailObjRelate = response.data;
      });
    } catch (err) {
      console.log(err);
    }
  },
  async tracingObject({ commit }, value) {
    let isSuccess = false;
    const {type,id} = value
    try {
      const response = await DataService.callApi(
        ConstantAPI.TRACING_OBJECT.TRACING,
        {
          ids: [id],
          tracing: true,
          type: Number(type),
        }
      );
      checkResponse(response, () => {
        isSuccess = true;
      });
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async getSuggestPerson({ state }, params) {
    try {
      state.loading = true;
      const response = await DataService.callApi(
        ConstantAPI.IO_SUGGEST.PERSON,
        null,
        params
      );
      checkResponse(response, () => {
        state.suggestPerson = response.data;
      });
      state.loading = false;
    } catch (err) {
      console.log(err);
    }
  },
  async getSuggestVehicle({ state }, params) {
    try {
      state.loading = true;
      const response = await DataService.callApi(
        ConstantAPI.IO_SUGGEST.VEHICLE,
        null,
        params
      );
      checkResponse(response, () => {
        state.suggestVehicle = response.data;
      });
      state.loading = false;
    } catch (err) {
      console.log(err);
    }
  },
  updateDataFormState({ state }, form) {
    state.formState.violation = form.violation;
    state.formState.objectType = form.objectType;
    state.formState.name = form.name;
    state.formState.characteristic = form.characteristic;
    state.formState.imagesAvatar = form.imagesAvatar;
    state.formState.imagesRelate = form.imagesRelate;
    state.formState.imagesRelateAdded = form.imagesRelateAdded;
    state.formState.imagesRelateDeleted = form.imagesRelateDeleted;
    state.formState.ioGroups = form.ioGroups;
    state.formState.relatedSuggestions = form.relatedSuggestions;
  },
  async getIdentificationHistory({ state }, params) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.EVENT.GET_BY_CONDITION,
        null,
        params
      );
      checkResponse(response, () => {
        state.listIdentHistory = response.data.content
          ? response.data.content
          : [];
        state.totalIdenHistory = response.data.totalElements;
      });
    } catch (err) {
      console.log(err);
    }
  },
  async deleteHistory({ state }, ids) {
    try {
      state.loading = true;
      const { t } = i18n.global;
      const response = await DataService.delete(
        ConstantAPI.EVENT.DELETE.url + ids
      );
      state.loading = false;
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('object_information.delete-image-success'),
            duration: 4,
          });
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('object_information.delete-image-fail'),
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async mergeProfile({ commit }, { id, idsSimilar }) {
    try {
      // BUG: fix tạm cho demo
      let success = false;
      const response = await DataService.callApi(
        ConstantAPI.OBJECT_MANAGEMENT.MERGE_PROFILE,
        { id, idsSimilar, type: 1 }
      );
      checkResponse(response, () => {
        success = true;
        commit('setLoading', false);
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async setListMergeProfile({ state }, listProfile) {
    state.listMergeProfile = listProfile.map(item => item.id);
  },
  async getListSimilarCreate({ state }, { keyword, imgUrl }) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
          ConstantAPI.OBJECT_MANAGEMENT.GET_BY_CONDITION,
          null,
          {
            name: keyword,
            imgUrl
          }
      );

      let success = false;
      checkResponse(
          response,
          () => {
            success = true;
            state.listSimilarCreate = response.data.content;
          },
          () => {
            success = false;
            notification.error({
              message: t('common.notification'),
              description: '',
              duration: 4,
            });
          }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
