import mutations from './mutations';
import {getField, updateField} from 'vuex-map-fields';
import {DataService} from '@/dataService/dataService';
// import { checkResponse } from '@/util/common-utils';
import ConstantAPI from '@/config/ConstantAPI';
import {checkResponse} from "@/util/common-utils";

const state = () => ({
    dataChart: {
        key: [],
        dataIn: [],
        dataOut: [],
    },
    dataVehicle: null,
    dataHuman: null,
    api: ConstantAPI.IN_OUT_EVENT,
    loading: false,
    keyValVehicle: [],
    listInOutEvent: [],
    totalInOutEvent: 0
});

const actions = {
    async getDataChart({commit, state}, params) {
        try {
            commit('setLoading', true)
            const response = await DataService.callApi(state.api.GET_CHART_BY_CONDITION, null, params);
            checkResponse(response, () => {
                if (response.data.chart) {
                    commit('setDataChart', response.data.chart)
                } else {
                    state.dataChart.key = []
                    state.dataChart.dataIn = []
                    state.dataChart.dataOut = []
                }
                // let array = response.data.vehicle.vehicleNameAgg
                // array.push(array.splice(array.findIndex(x => x.key.toLowerCase() === 'other'), 1)[0])
                state.dataVehicle = response.data.vehicle
                state.dataHuman = response.data.human
            });
            commit('setLoading', false)
        } catch (err) {
            console.log(err);
        }
    },
    async getKeyValVehicle({state}) {
        try {
            const response = await DataService.callApi(ConstantAPI.APP_PARAMS, null, { type: 'VehicleType' });
            checkResponse(response, () => {
                if (response.data.length) {
                    response.data.forEach(e => {
                       state.keyValVehicle[e.value] = e.name
                    })
                }
            });
        } catch (err) {
            console.log(err);
        }
    },
    async getList({ state }, {params, reset}) {
        try {
            const response = await DataService.callApi(state.api.LIST, null, params);

            if (reset) {
                state.listInOutEvent = [];
            }

            checkResponse(response, () => {
                state.listInOutEvent = response.data.content ? state.listInOutEvent.concat(response.data.content) : state.listInOutEvent;
                state.totalInOutEvent = response.data.totalElements;
            });
        } catch (err) {
            console.log(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
